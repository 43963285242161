/* ======================================================================== */
/* 58. section */
/* ======================================================================== */
.section
	position: relative
.section-fullheight
	display: flex
.section-fullheight__inner
	position: relative
	display: flex
	flex-direction: column
	justify-content: center
	width: 100%
	min-height: 100vh
.section-fullheight__inner_mobile
	min-height: calc(var(--fix-bar-vh, 1vh) * 100)
.section_z-100
	z-index: 100
.section__content
	position: relative
	z-index: 60
.section__headline
	display: block
	@include fluid('width', 60, 120)
	height: 1px
	background-color: $brown-grey
.section__bg
	position: absolute
	top: 0
	left: 0
	width: 100%
	height: 100%
	overflow: hidden
.section__circle
	@extend .slider__circle

@media screen and (max-width: $md)
	.section-fullheight__inner_mobile-auto
		min-height: 0
		height: 100%
		display: block
