/* ======================================================================== */
/* 2. arrow */
/* ======================================================================== */
.arrow
	position: relative
	display: inline-flex
	align-items: center
	justify-content: center
	vertical-align: middle
	cursor: pointer
	outline: none
	.svg-circle
		width: 60px
		height: 60px
	.circle
		stroke: $borders-dark-2
		stroke-width: 1px
	&:hover
		.arrow__pointer
			background-color: var(--color-dark-2)
		.circle
			stroke: var(--color-dark-2)
		.arrow__triangle
			border-color: transparent transparent transparent var(--color-dark-2)

.arrow_mini
	.svg-circle
		width: 40px
		height: 40px
	.arrow__pointer
		width: 50% !important
		right: 22px
	.arrow__triangle
		border-width: 4px 0 4px 6px
	.circle
		stroke-width: 2px

.js-arrow
	&:hover
		.arrow__pointer
			transform: scaleX(0.3) translateX(24px)
			width: 100% !important
		.arrow__triangle
			transform: translate(calc(-50% + 6px), -50%)

.arrow-left
	transform: rotate(180deg)

.arrow__pointer
	display: block
	position: absolute
	width: 100%
	height: 2px
	right: 30px
	top: 0
	bottom: 0
	margin: auto
	background-color: $borders-dark-2
	+trans1
	transform-origin: right center

.arrow__triangle
	display: inline-block
	border-style: solid
	border-width: 6px 0 6px 8px
	border-color: transparent transparent transparent $borders-dark-2
	position: absolute
	top: 50%
	left: 50%
	transform: translate(-50%, -50%)
	+trans1

.arrow-up
	transform: rotate(-90deg)
	.arrow__pointer
		width: 80%

.arrow-down
	transform: rotate(90deg)
	.arrow__pointer
		width: 80%

@media screen and (max-width: $md)
	.arrow
		width: 36px
		height: 36px
		.svg-circle
			width: 36px
			height: 36px
		.arrow__pointer
			width: 50% !important
			right: 20px
			// top: 2px
		.arrow__triangle
			top: 50%
			border-width: 4px 0 4px 6px
		.circle
			stroke-width: 2px
